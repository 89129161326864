@import "@scss/common/variables";

.loading-wrapper {
  width: 100%;
  height: calc(80vh - 135px);
  background-color: $white-color;
  cursor: pointer;
}
.loading-wrapper__details {
  margin: auto;
}
.loading-image {
  height: 500px;
  width: 500px;
  text-align: center;
  // border: 1px dashed $theme-border-color;
  // border-radius: 4px;
  transition: 0.3ms all ease-in-out;
}
.no-file-image{
  // height: 800px;
  // width: 800px;
  text-align: center;
  // border: 1px dashed $theme-border-color;
  // border-radius: 4px;
  transition: 0.3ms all ease-in-out;
}

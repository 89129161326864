@import '@scss/common/variables';
.user-details-wrapper__header--new-header {
	position: sticky;
	top: 65px;
	background-color: $white-color;
	z-index: 1;
}
.user-details-wrapper__cta-btns {
	display: inline-flex;
	align-items: center;
	gap: 8px;
	.user-details-wrapper__cta-btn {
		height: 40px;
		line-height: 40px;
		border-radius: 4px;
		border: 1px solid $theme-border-color;
		background-color: $white-color;
		transition: 0.3s all ease-in-out;
		min-width: 98px;
		max-width: 130px;
		width: 100%;
		margin: 4px 0;
		text-align: center;
		cursor: pointer;
		&--primary {
			color: rgba(var(--button-primary), 1);
			background-color: #ffffff;
			margin-right: 8px;
			&:hover {
				background-color: rgba(var(--button-primary), 1);
				color: #ffffff;
			}
		}
		&--secondary {
			background-color: rgba(var(--button-primary), 1);
			color: #ffffff;

			margin-right: 8px;
			&:hover {
				color: rgba(var(--button-primary), 1);
				background-color: #ffffff;
			}
		}
	}
}
.user-details-wrapper__header-title {
	font-weight: 400;
	color: $theme-font-primary-color;
}
.open-ticket-wrapper {
	max-width: 1150px;
	min-width: 1150px;
	margin: 0 auto;
	align-items: flex-start;
	justify-content: space-between;
	@media (min-width: 992px) and (max-width: 1200px) {
		max-width: 992px;
		min-width: 992px;
		justify-content: space-around;
	}
}
.module-wrapper {
	padding: 24px 0px 24px 0;
	position: relative;
	&::after {
		content: '';
		height: 100%;
		width: 1px;
		background-color: $theme-border-color;
		position: absolute;
		top: 0;
		right: -90px;
		@media (min-width: 992px) and (max-width: 1200px) {
			right: -24px;
		}
	}
}
.module-wrapper__form-group {
	margin-bottom: 16px;
}
.module-wrapper__form-group label.module-wrapper__label {
	color: $theme-font-heading-color;
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 8px;
}
.module-wrapper__form-group select.module-wrapper__select {
	max-width: 650px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	border: 1px solid $theme-border-color;
	border-radius: 4px;
	padding: 0 14px;
	color: $theme-font-primary-color;
	font-size: 16px;
	font-weight: 400;
	@media (min-width: 992px) and (max-width: 1200px) {
		max-width: 600px;
	}
	option {
		color: $theme-font-primary-color;
		font-size: 16px;
		font-weight: 400;
	}
}
.status-details-wrapper {
	padding-top: 24px;
}
.status-details-wrapper__form-group label.status-details-wrapper__label {
	color: $theme-font-primary-color;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 8px;
}
.status-details-wrapper__form-group .status-details-wrapper__select {
	max-width: 364px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	border: 1px solid $theme-border-color;
	border-radius: 4px;
	padding: 0 14px;
	color: $green-color;
	@media (min-width: 992px) and (max-width: 1200px) {
		max-width: 300px;
	}
	option.green-color {
		color: $green-color;
	}
}
.status-details-wrapper__form-group input.status-details-wrapper__form-control {
	max-width: 364px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	background-color: #f7f7f7;
	color: $theme-font-heading-color;
	@media (min-width: 992px) and (max-width: 1200px) {
		max-width: 300px;
	}
	&::placeholder {
		color: $theme-font-heading-color;
	}
}
.upload-wrapper__header {
	border-bottom: 1px solid $theme-border-color;
}
.module-wrapper__text-details {
	max-width: 650px;
	width: 100%;
	border: 1px solid $theme-border-color;
	padding: 16px;
	border-radius: 4px;
	@media (min-width: 992px) and (max-width: 1200px) {
		max-width: 600px;
	}
}
.module-wrapper__text {
	font-size: 12px;
	color: $theme-font-primary-color;
	font-weight: 300;
}
.comments-wrapper__header {
	border-top: 1px solid $theme-border-color;
	border-bottom: 1px solid $theme-border-color;
	text-align: center;
	padding: 12px 0;
	h6 {
		color: rgba(var(--primary-color), 1);
		position: relative;
		font-weight: 400;
		max-width: 70px;
		width: 100%;
		margin: 0 auto;
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			top: 33px;
			width: 100%;
			height: 1px;
			background-color: rgba(var(--primary-color), 1);
		}
	}
}
.upload-wrapper {
	border: 1px solid $theme-border-color;
	border-radius: 4px;
	padding: 16px;
	max-width: 364px;
	width: 100%;
	@media (min-width: 992px) and (max-width: 1200px) {
		max-width: 300px;
	}
}
.upload-wrapper__header {
	padding-bottom: 10px;
}
.attach-btn {
	background-color: $theme-border-color;
	border-radius: 4px;
	border: none;
	color: $white-color;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	padding: 0 16px;
	margin-right: 16px;
}
.upload-wrapper__header-text {
	color: $theme-font-primary-color;
	font-size: 12px;
	font-weight: 400;
}
.billing-data {
	margin: 16px 0 0;
	padding: 16px;
	background-color: #f9faff;
	max-width: 268px;
	width: 100%;
	border-radius: 4px;
}
.billing-data__header {
	align-items: flex-start;
	margin-bottom: 12px;
}
.billing-text {
	color: $theme-font-primary-color;
	font-weight: 500;
	font-size: 12px;
	max-width: 200px;
	width: 100%;
	margin: 0 auto;
}

@import '@scss/common/variables';
.client-list-details-wrapper {
	width: 100%;
	position: relative;
	padding-bottom: 100px;
	.client-list-details-wrapper__illustration {
		position: absolute;
		bottom: 24px;
		right: 24px;
	}
	.client-list-details-wrapper__header {
		width: 100%;
		margin: 0px auto;
		padding: 25px 0 16px;
		border-bottom: 1px solid $theme-border-color;
		.client-list-details-wrapper__nav-list {
			max-width: 510px;
			width: 100%;
			margin: 0 auto;
		}
		.client-list-details-wrapper__nav-list-item {
			&:not(:last-child) {
				margin-right: 32px;
			}
			.client-list-details-wrapper__nav-list-item-link {
				font-size: 14px;
				color: $theme-font-primary-color;
				&.active {
					position: relative;
					color: var(--primary-color);
					&::after {
						content: '';
						position: absolute;
						bottom: -16px;
						left: 0;
						right: 0;
						height: 1px;
						width: 100%;
						background-color: var(--primary-color);
					}
				}
			}
		}
	}
	.client-list-details-wrapper__back-btn {
		position: absolute;
		left: 24px;
		top: 20px;
	}
	.client-list-details-wrapper__organization-info {
		max-width: 1020px;
		width: 100%;
		margin: 0 auto;
		border-bottom: 1px solid $theme-border-color;
		padding: 24px 0 17px;
		.client-list-details-wrapper__organization-title {
			color: #3c4043;
			font-size: 20px;
			font-weight: 500;
		}
		.client-list-details-wrapper__cta-btn {
			font-size: 16px;
			padding: 0px 24px;
			border-radius: 4px;
			height: 41px;
			line-height: 41px;
			border: 1px solid transparent;
			cursor: pointer;
			min-width: 98px;
			&--secondary {
				color: rgba(var(--button-primary), 1);
				background-color: $white-color;
				transition: 0.3s all ease-in-out;
				border: 1px solid $theme-border-color;
				margin-left: 16px;
				&:hover {
					background-color: rgba(var(--button-primary), 1);
					color: $white-color;
					border-color: transparent;
				}
			}
			&--primary {
				background-color: rgba(var(--button-primary), 1);
				color: $white-color;
				transition: 0.3s all ease-in-out;
				margin-left: 16px;
				&:hover {
					color: rgba(var(--button-primary), 1);
					background-color: $white-color;
					border: 1px solid $theme-border-color;
				}
			}
		}
	}
	.client-list-details-wrapper__form {
		max-width: 752px;
		width: 100%;
		margin: 24px auto;
		.client-list-details-wrapper__form-group {
			max-width: 364px;
			width: 100%;
			margin-bottom: 16px;
			&--company-add {
				max-width: 100%;
			}
			label.client-list-details-wrapper__label {
				font-weight: 500;
				color: $theme-font-primary-color;
				font-size: 14px;
				margin-bottom: 8px;
			}
		}
		.client-list-details-wrapper__psycology-header {
			margin-bottom: 17px;
			.client-list-details-wrapper__psycology-header-title {
				color: $theme-font-primary-color;
				font-size: 14px;
				font-weight: 500;
			}
		}
		.client-list-details-wrapper__psycology {
			align-items: flex-start;
			background-color: #e1f5fe;
			border-radius: 2px;
			padding: 9px 10px;
			.client-list-details-wrapper__psycology-text {
				color: rgba(var(--secondary-color), 1);
				font-size: 12px;
				margin-left: 8px;
			}
		}
	}
}
.flex-wrap {
	flex-wrap: wrap;
}

.form-group input.form-control {
	height: 44px;
	line-height: 44px;
	border: 1px solid $theme-border-color;
	font-size: 16px;
	color: #333333;
	&::placeholder {
		color: $theme-border-color;
	}
	&:focus {
		box-shadow: none;
		border-color: $theme-border-color;
	}
}

.client-list-details-wrapper__form-group .form-group {
	.css-1pahdxg-control {
		box-shadow: none;
		border-color: $theme-border-color !important;
		min-height: 44px;
	}
	.css-1pahdxg-control:hover {
		border-color: $theme-border-color !important;
	}
	textarea {
		color: #333333;
		&:focus {
			box-shadow: none;
			border-color: $theme-border-color;
		}
	}
}

@import '@scss/common/variables';
.list-management-wrapper {
	margin-top: 65px;
	max-width: 100%;
	overflow-x: scroll;
}
.list-managemnet-wrapper__country-table {
	//margin-top: 50px;
	padding-top: 32px;
	border-right: 1px solid $theme-border-color;
	min-width: 502px;
	width: 100%;

	.list-managemnet-wrapper__country-common-container {
		padding-left: 24px;
		border-bottom: 1px solid $theme-border-color;

		.list-managemnet-wrapper__form-group {
			position: relative;
			max-width: 308px;
			width: 100%;
			height: 61px;

			input.list-managemnet-wrapper__form-control {
				max-width: 308px;
				width: 100%;
				border: 1px solid $theme-border-color;
				border-radius: 4px;
				font-size: 16px;
				height: 44px;
				line-height: 44px;
				&::placeholder {
					color: $theme-font-heading-color;
				}
			}
			.list-managemnet-wrapper__form-add-icon {
				position: absolute;
				right: 12px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	.list-managemnet-wrapper__title {
		padding: 16.5px 24px 0px;
		font-size: 16px;
		color: $theme-font-heading-color;
		margin-bottom: 30px;
	}
	.list-managemnet-wrapper__country-items {
		padding: 14px 24px 13px;
		margin-left: 26px;
		border-radius: 8px;
		transition: 0.3s all ease-in-out;
		cursor: pointer;
		&:hover {
			background-color: $background-primary-color;
			.list-managemnet-wrapper__country-items-title {
				color: var(--primary-color);
			}
		}
		&.active {
			background-color: $background-primary-color;
			color: var(--primary-color);
		}
		.list-managemnet-wrapper__country-items-title {
			font-size: 14px;
			color: $theme-font-primary-color;
			font-weight: 400;
		}
		.list-managemnet-wrapper__country-items-alter img {
			&:not(:last-child) {
				margin-right: 16px;
			}
		}
	}
}
.list-management-wrapper__scroll-table {
	max-height: calc(100vh - 300px);
	overflow-y: auto;
}
.list-managemnet-wrapper--max-height {
	overflow-y: hidden;
	max-height: calc(100vh - 120px);
}
.custom-input-file {
	border: 1px dashed $theme-border-color;
	width: 100%;
	min-width: 440px;
	padding: 24px 0;
	border-radius: 4px;
}
.input-file-label:hover .custom-input-file {
	border: 1px dashed #276ef1;
}
.custom-input-file__text {
	color: #5f6368;
	margin-bottom: 4px;
}

@import "@scss/common/variables";
.popup-wrapper {
  width: 100%;
  max-height: calc(100vh - 65px);
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 4, 4, 0.5);
  z-index: 6;
}
.popup-wrapper__details {
  margin: auto;
  box-shadow: 4px 4px 10px #00000029;
  border-radius: 4px;
  padding: 24px;
  max-width: 450px;
  width: 100%;
  background-color: $white-color;
  z-index: 19;
}
.popup-wrapper__download {
  text-align: right;
  margin-bottom: 24px;
}
.popup-wrapper__download-btn {
  border: 1px solid $theme-border-color;
  border-radius: 4px;
  height: 44px;
  line-height: 44px;
  padding: 0 24px;
  background-color: #f7f7f7;
  color: #5f6368;
  font-size: 16px;
}
.popup-wrapper__import {
  margin-bottom: 16px;
}
.popup-wrapper__import-title {
  color: #02052b;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
}
.popup-wrapper__import-text {
  color: #5f6368;
  font-size: 14px;
  span {
    font-weight: 500;
  }
}
.popup-wrapper__upload-file {
  background-color: #f7f7f7;
  padding: 28px 0;
  margin-bottom: 16px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.3ms all ease-in-out;
  &:hover {
    border: 1px dashed rgba(var(--button-primary), 1);
  }
}
.popup-wrapper__upload-file-details {
  margin: 0 auto;
  text-align: center;
}
.popup-wrapper__cta-btns {
  text-align: right;
}
.popup-wrapper__cta-button {
  border: 1px solid $theme-border-color;
  border-radius: 4px;
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
  &--primary {
    color: $white-color;
    background-color: rgba(var(--button-primary), 1);
  }
  &--secondary {
    color: rgba(var(--button-primary), 1);
    background-color: $white-color;
  }
}
.color-blue {
  color: rgba(var(--button-primary), 1);
  font-weight: 500;
  margin: 2px 0;
}
.popup-wrapper__upload-file-text {
  color: #dadce0;
  font-size: 14px;
}
.popup-wrapper__data-imported {
  color: #02052b;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 22px;
}
.popup-wrapper__records {
  color: #3c4043;
  font-size: 20px;
  font-weight: 400;
  margin: 30px 0 1px;
}
.popup-wrapper__close-btn {
  text-align: right;
}

@import "@scss/common/normalize";
@import "@scss/common/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
a {
  margin: 0;
  padding: 0;
}
.main-wrapper-login {
  width: 100%;
  display: flex;
  height: 100vh;
  // height: calc(100vh - 65px);
}

.flex-1 {
  flex: 1;
}

.login-wrapper {
  background-color: #364f70;
  max-width: 576px;
  min-height: 768px;
}
.login-wrapper img {
  margin: 80px 99px 47px;
  width: 365px;
}
.login-wrapper h3 {
  color: $white-color;
  text-align: center;
  max-width: 238px;
  width: 100%;
  margin: 0 auto 8px;
  font-size: 22px;
  font-weight: 500;
}
.login-wrapper p {
  color: $white-color;
  margin: 0 auto;
  text-align: center;
  max-width: 419px;
  width: 100%;
}
.form-wrapper img {
  width: 173px;
  float: right;
}
.logo {
  top: 40px;
  right: 40px;
}
.form-wrapper__form {
  margin: 0 auto;
  max-width: 364px;
  width: 100%;
}
.form-wrapper__form-title {
  margin-bottom: 32px;
  font-size: 34px;
  font-weight: 500;
}
.form-wrapper {
  .form-wrapper__form-common-title {
    margin-bottom: 32px;
    color: $black-color;
    font-weight: 500;
    &--email {
      margin-bottom: 8px;
    }
  }
  .form-wrapper__form-text-description {
    margin-bottom: 40px;
    max-width: 326px;
    width: 100%;
    font-size: 20px;
    color: #423f3f;
  }
}
.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  color: #423f3f;
  margin-bottom: 4px;
  font-size: 14px;
}
.form-group input.login {
  border: 1px solid #e8f0fe;
  max-width: 364px;
  width: 100%;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
  padding: 0 24px;
}
.form-group .form-control--user-id {
  background-color: #f7f7f7;
  color: #9d9d9d;
  font-size: 16px;
  width: 100%;
  height: 56px;
  line-height: 56px;
}
.form-group input::placeholder {
  color: #9d9d9d;
}
.form-cta-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-cta-button__secondary-btn {
  border: none;
  background-color: transparent;
  color: #1a73e8;
  cursor: pointer;
}
.form-cta-button__primary-btn {
  color: $white-color;
  text-align: center;
  height: 56px;
  line-height: 56px;
  max-width: 181px;
  width: 100%;
  border: none;
  background-color: #1a73e8;
  border-radius: 4px;
  cursor: pointer;
  &--reset-btn {
    max-width: 100%;
  }
}
.back-btn {
  display: flex;
  align-items: center;
  max-width: 100px;
  width: 100%;
  color: #1a73e8;
  font-size: 14px;
}

.back-arrow {
  height: 24px;
  width: 24px !important;
  margin-right: 8px;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events.swiper-backface-hidden {
  padding-bottom: 50px;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 10px;
}
.swiper-pagination-bullet {
  &.swiper-pagination-bullet-active {
    background-color: #fd9933;
  }
}

.captcha_container{
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.captcha_input{
  height: 40px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  padding-left: 10px;
  border: 1px solid #dadce0;
  &:focus{
    border: 1px solid #1a73e8;;

  }
}
.captcha_reload{
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}
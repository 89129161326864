@import "@scss/common/variables";
.compelte-task-wrapper {
	position: fixed;
	box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
	top: 10px;
	height: 100%;
	max-width: calc(100% - 350px);
	width: 100%;
	right: 10px;
	background-color: $white-color;
	z-index: 20;
	transform: translateX(120%);
	transition: all 0.8s ease-in-out;
	border-left: 1px solid $theme-border-color;
	max-height: calc(100vh - 20px);
    overflow-y: hidden;
}

.compelte-task-wrapper-add {
	position: fixed;
	box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
	top: 10px;
	height: 100%;
	max-width: calc(40%);
	width: 100%;
	right: 10px;
	background-color: $white-color;
	z-index: 20;
	transform: translateX(120%);
	transition: all 0.8s ease-in-out;
	border-left: 1px solid $theme-border-color;
	max-height: 100vh;
	overflow-y: auto;
}

.compelte-task-wrapper--active {
	transform: translateX(0);
}
.compelte-task-wrapper__header {
	padding: 12px;
	background-color: #1a73e8;
}
.compelte-task-wrapper__title {
	color: #ffffff;
}

.compelte-task-wrapper__completion {
	padding: 24px;
	border-right: 1px solid $theme-border-color;
}
.compelte-task-wrapper__response {
	padding: 24px;
	border-right: 1px solid $theme-border-color;
}
.compelte-task-wrapper__form-group {
	margin: 16px 0;
}
.compelte-task-wrapper__status {
	max-width: 364px;
	width: 100%;
	border-radius: $base-border-radius;
	height: 44px;
	line-height: 44px;
	padding: 0 16px;
	color: $theme-primary-color;
	border: 1px solid $theme-border-color;
	background-color: #f7f7f7;
	&--width {
		max-width: 100%;
		color: $theme-font-primary-color;
	}
}
.compelte-task-wrapper__label {
	margin-bottom: 8px;
	font-weight: $medium-font-weight;
	color: $theme-font-heading-color;
}
.compelte-task-wrapper__label_popup {
	font-weight: $medium-font-weight;
	color: $theme-font-heading-color;
}
.compelte-task-wrapper__common-title {
	color: $theme-primary-color;
	font-weight: $medium-font-weight;
}

.compelte-task-wrapper__text-area {
	padding: 10px;
	border: 1px solid $theme-border-color;
	border-radius: $base-border-radius;
	&::placeholder {
		color: $theme-border-color;
	}
}
.custom-file {
	border: 1px solid $theme-border-color;
	border-radius: $base-border-radius;
	padding: 24px;
	min-width: 690px;
	width: 100%;
}
.custom-file--attach {
	background-color: #f7f7f7;
	padding: 0 16px;
	height: 32px;
	line-height: 32px;
	border-radius: $base-border-radius;
	cursor: pointer;
}
.compelte-task-wrapper__uploaded {
	width: 100%;
	height: 44px;
	line-height: 44px;
	padding: 0 16px;
	background-color: #f7f7f7;
	border-radius: $base-border-radius;
	span{
		font-size: 13px;
	}
	&:not(:last-child) {
		margin-bottom: 8px;
	}
}

.compelte-task-wrapper__response {
	max-height: calc(100% - 47px); //47px is for modal header height
	overflow-y: auto;
}

.compelte-task-wrapper__completion {
	max-height: calc(100% - 47px);
	&.padd-0 {
		padding: 0;
	}

	&.compelte-task-wrapper__completion_fixed_button {	
		position: relative;

		.complete-task-wrapper__completion-holder {
			padding: 24px;
			// max-height: calc(100% - 47px);
			// max-height: calc(100vh - 67px);
			max-height: calc(100vh - 67px - 65px);
			height: calc(100vh - 67px - 65px);
			overflow-y: auto;
		}
	
		.compelte-task-wrapper__cta {
			position: absolute;
			border-top: 1px solid $theme-border-color;
			width: 100%;
			justify-content: right;
			height: 50px;
			bottom: 0;
			.ascent-button {
				height: 34px;
				line-height: normal;
				padding: 8px 16px;
				margin: 5px 8px 4px 0;
				min-width: auto;
			}
		}
	}
}

.compelte-task-wrapper__response {
	.grid {
		.input-grid-item {
			width: 100%;
			margin-right: 0;
		}
	}
}


//View Blogs CSS
.max-height-blog {
    position: relative;
    height: calc(100vh - 67px - 50px);

    .view-blog-content-wrapper {
        height: calc(100vh - 47px - 20px - 50px);
        overflow-y: auto;
        padding: 24px;

        .blog-content {
            // position: relative;
            // height: calc(100vh - 67px - 50px);

            .blog-title {
                font-size: 16px;
                font-weight: $medium-font-weight;
                margin-bottom: 8px;
            }

            .created-author-wrap {
                font-size: 10px;
                margin-bottom: 8px;
                .font-timestamp-color {
                    color: rgba(0,0,0,.5);
                }

                .time-stamp {
                    margin-right: 10px;
                    padding-right: 5px;
                    border-right: 1px solid $theme-border-color;
                }
            }

            .blog-tags-wrap {
                .legal-tags {
                    color: #115baa;
                    background: #D0DDEE;
                    font-size: 12px;
                    padding: 2px 4px;
                    margin-right: 5px;
                    border-radius: 2px;

					&.states-name-tags {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						color: #ffffff;
						background: #A289DD;
					
					}
                }
            }

            .blog-desc {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 8px;

				// all: inherit;
				all: inherit;
				display: block;

				ul, li {
					list-style: inherit;
				}

				* {
					all: revert
				}
            }

            .blog-docs-wrap {
                color: #1a73e8;
                font-size: 14px;
            }
        }

        .compelte-task-wrapper__cta {
            border-top: 1px solid $theme-border-color;
            border-right: 1px solid $theme-border-color;
            bottom: -50px;
            height: 50px;
            justify-content: right;
            position: absolute;
            width: 100%;
            left: 0;

            .ascent-button {
              height: 34px;
              line-height: normal;
              margin: 5px 8px 4px 0;
              min-width: auto;
              padding: 8px 16px;
            }
          }
    }
}

.document-url-iframe {
	max-height: calc(100vh - 150px);
	height: calc(100vh - 150px);
	width: 100%;
}


// .blog-desc p,
// .blog-desc ul,
// .blog-desc ol,
// .blog-desc strong,
// .blog-desc ins {
//   all: initial;
// }
:root {
	--primary-color: 215, 36, 46;
	--secondary-color: 0, 91, 166;
	--button-primary: 38, 110, 241;
}

* {
	font-variant-ligatures: none;
	font-feature-settings: "liga" 0;
  }

@import 'normalize.scss';
@import 'extends.scss';

.theme-primary-color {
	color: $theme-primary-color;
}

.theme-font-secondary-color {
	color: $theme-secondary-color;
}
.resend-btn {
	border: none;
	background-color: transparent;
	color: $theme-secondary-color;
	text-transform: uppercase;
	margin-left: 24px;
}
.resend-btn img {
	margin: 0 auto 4px;
}

.flat-button {
	background-color: transparent;
	border: none;

	&--no-padding {
		padding: 0;
	}
}

.ascent-button {
	height: 56px;
	line-height: 56px;
	margin: 4px 0;
	border-radius: 4px;
	border: none;
	background-color: #ffffff;
	min-width: 165px;

	&--header-buttons {
		height: 40px;
		line-height: 40px;
		min-width: 130px;
	}

	&--primary {
		color: rgba(var(--button-primary), 1);
		background-color: #ffffff;
		margin-right: 8px;
		border: 1px solid $theme-border-color;
		padding: 0 40px;
	}

	&--secondary {
		background-color: rgba(var(--button-primary), 1);
		color: #ffffff;
		padding: 0 24px;
	}
}

.theme-button {
	padding: 15px 24px;
	border-radius: $base-border-radius;
	border: 1px solid $theme-border-color;
	@extend %text-capitalize;

	&.theme-primary-btn {
		background-color: $theme-primary-color;
		color: $white-color;
	}

	&.theme-secondary-btn {
		background-color: #ffff;
		// color: $theme-link-secondary-color;
	}

	&.theme-white-btn {
		background-color: #ffffff;
		//  color: $theme-primary-light-color;
	}

	&.theme-white-secondary-button {
		background-color: $white-color;
		color: $theme-primary-color;
	}
}

.initial-line-height {
	line-height: 1;
}

.margin-auto {
	margin: auto;
}

.custom-container {
	max-width: 1095px;
	margin: 0 auto;
	padding: 0 24px;

	@media (max-width: $mobile-devices-max-width+px) {
		padding: 0 16px;
	}
}

.white-color {
	@extend %white-color;
}

.theme-secondary-text-color {
	@extend %theme-secondary-text-color;
}

.theme-secondary-color {
	color: $theme-secondary-color;
}

.normal-font {
	@extend %normal-font;
}

.light-font {
	@extend %light-font;
}

.bold-font {
	@extend %bold-font;
}

.medium-bold-font {
	@extend %medium-bold-font;
}

.flex-wrap {
	flex-wrap: wrap;
}

.arrow {
	&.swiper-button-disabled {
		opacity: 0.33;
		pointer-events: none;
	}

	img {
		height: 36px;
		width: 36px;
		border: 1px solid $theme-border-color;
		border-radius: 50%;
		padding: 8px;
		opacity: 1;

		&:hover {
			background-color: #fabc2c;
		}

		@media (max-width: $mobile-devices-max-width+px) {
			height: 30px;
			width: 30px;
		}
	}
}

.section-padding {
	padding: 54px 0;

	@media (max-width: $mobile-devices-max-width+px) {
		padding: 34px 0;
	}
}

@media (min-width: $tablet-devices-min+px) {
	.d-none-tablet-above {
		display: none;
	}
}

@media (max-width: $mobile-devices-max-width+px) {
	.d-none-mobile {
		display: none !important;
	}

	.flex-direction-column-mobile {
		flex-direction: column;
	}

	.flex-direction-column-reverse-mobile {
		flex-direction: column-reverse;
	}

	.mobile-theme-button {
		height: 36px;
	}
}

@media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
	.flex-direction-column-tablet {
		flex-direction: column;
	}
}

.form-group {
	label {
		margin-left: 5px;
		@extend %light-font;
		font-size: (14 / $base-font-size) + rem;
	}

	textarea {
		resize: none;
	}

	.form-control {
		@extend %placeholder;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.small-font {
	font-size: (12 / $base-font-size) + rem;
}

.display-inline-block {
	display: inline-block;
}

.margin-24 {
	margin: 24px 0;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

select {
	&.reset-styling {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		border: none;
		//background-image: url("/svg/generic-icons/dropdown-caret.svg");
		background-position: right center;
		background-repeat: no-repeat;
		padding-right: 30px;
	}
}

// .pagination-wrapper {
//   padding: 20px 0 0px;
//   .page-link {
//     color: $theme-font-primary-color;
//     border: none;
//   }
//   ul.pagination {
//     li {
//       &.disabled {
//         display: none;
//       }
//       a {
//         color: $theme-font-primary-color;
//         border: none;
//         padding: 5px;
//         margin-right: 3px;
//         &[aria-label="Go to next page"] {
//           color: $theme-primary-color;
//         }
//       }
//       &.active {
//         a {
//           color: $theme-primary-color;
//         }
//       }
//     }
//   }
// }
.pagination {
	justify-content: center;

	li {
		@extend %display-inline-block;

		&:nth-child(1),
		&:nth-child(2),
		&:last-child,
		&:nth-last-child(2) {
			a {
				background-color: transparent;
				font-size: 1rem;
				width: initial;
				height: initial;
				font-weight: $bold-font-weight;
			}

			&:hover {
				a {
					background-color: transparent;
					color: $theme-secondary-color;
				}
			}
		}

		&.active,
		&:hover {
			a {
				background-color: #2e489e;
				color: $white-color;
			}
		}

		a {
			@extend %display-inline-block;
			width: 30px;
			height: 30px;
			text-align: center;
			background-color: $theme-border-color;
			margin: 0 5px;
			border-radius: 50%;
			line-height: 30px;
			font-size: 12px;
			@extend %transition;
		}
	}
}

input {
	&.form-control {
		height: 44px;
		border: 1px solid $theme-border-color;
	}
}

.form-error {
	color: rgb(236, 55, 55);
	margin-top: 2px;
	display: block;
	font-size: 12px;
	margin: 0;
	opacity: 0.8;
}

.search-text-box-wrapper {
	img {
		position: absolute;
		right: 10px;
	}

	.search-img {
		img {
			position: absolute;
			left: 0%;
			top: 50%;
			transform: translate(50%, -50%);
			width: 20px;
		}
	}

	input.form-control {
		padding-left: 35px;

		&::placeholder {
			color: #bec6d2;
		}

		&.search-box {
			height: 36px;
			width: 364px;
			border: 1px solid #dadce0;
			border-radius: 4px;
		}
	}
}

.footer-wrapper {
    height: 56px;
	align-items: center;
	// margin: 0px 0 24px;
	margin: 0px !important;
	border-bottom: 1px solid $theme-border-color;
	// border-top: 1px solid $theme-border-color;
	padding-right: 24px;
	overflow: hidden;
	bottom: 0;
	z-index: 1;
}

.default-checkbox {
	opacity: 0;
	visibility: hidden;
}

.default-checkbox:checked ~ .custom-checkbox {
	// background-color: #d7242e;
	background-color: #1a73e8;
	border-radius: 4px;

	.uploaded-tick {
		display: block;
	}
}

.table-hover-row-color {
	&:hover {
		background-color: rgba(
			var(--primary-color),
			0.06
		); //dynamic hover color according to theme
		// background-color: #f7faff;
		cursor: pointer;
	}
}
//reacct form-elements

// .css-1s2u09g-control {
// 	min-height: 34px !important;
// 	height: 34px !important;
// 	line-height: 34px;
// 	background-color: #fff;
// }
// .css-1s2u09g-control {
// 	height: 34px !important;
// 	line-height: 34px;
// }
.css-319lph-ValueContainer {
	padding: 0;
}
.form-error {
	margin-top: 4px;
}
.custom-checkbox--bg-blue {
	border: 1px solid $theme-border-color;
	height: 18px;
	width: 18px;
	border-radius: 4px;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
	background-color: #fff;
	display: flex;
}
.default-checkbox:checked ~ .custom-checkbox--bg-blue {
	background-color: #1a73e8;
	border-radius: 4px;

	.uploaded-tick {
		display: block;
	}
}

//react date-picker
.react-date-picker__wrapper {
	border: 1px solid $theme-border-color;
	border-radius: $base-border-radius;
	cursor: pointer;
	height: 34px;
	display: flex;
	align-items: center;
	transition: 0.3s all ease-in-out;
	&:hover {
		border-color: #1a73e8;
	}
}

.react-date-picker__inputGroup__input {
	cursor: pointer;
}
.react-date-picker__inputGroup__input {
	padding: 0 12px;
	border-radius: $base-border-radius;
	height: 34px;
}
.react-date-picker__inputGroup {
	display: flex;
	align-items: center;
}

//custom-radio
.custom-radio-button-input:checked ~ .radio-wrapper {
	border-color: rgba(var(--primary-color), 1);

	.custom-radio {
		background-color: rgba(var(--primary-color), 1);
	}
}

.radio-wrapper {
	height: 18px;
	width: 18px;
	border-radius: 50%;
	border: 1px solid $theme-font-heading-color;
	cursor: pointer;
	@extend %transition;

	&:hover {
		border-color: rgba(var(--primary-color), 1);

		.custom-radio {
			background-color: rgba(var(--primary-color), 1);
		}
	}
}

.custom-radio {
	height: 12px;
	width: 12px;
	border-radius: 50%;
	border: 1px solid $white-color;
	margin: auto;
}
.map-vendor__radio-button {
	top: 50%;
	transform: translateY(-50%);
	left: 8px;
}
.footer-wrapper-new {
	position: absolute;
	left: 0;
	right: 0;
}
.dropdown .show {
	display: block;
	max-height: 250px;
	height: 250px;
	overflow-y: auto;
	margin-top: -10px;
}

.dropdown-menu.custom-menu.show{
	display: block;
	max-height: 250px;
	height: 150px;
	overflow-y: auto;
	margin-top: 58px !important;
}
.scrollable_table_container{
	height: calc(100vh - 50px - 60px - 121px) !important;
	overflow-y: auto;
	overflow-x: auto;
}
.scrollable_table_container_nav{
	height: calc(100vh - 50px - 60px - 141px - 20px) !important;
	overflow-y: auto;
	overflow-x: auto;
}

.delete-popup-wrapper {
	.add-popup-wrapper__popup {
		height: auto;
		min-height: auto;
		padding: 20px;
	}
}

//Editor CSS
.rdw-editor-main {
	height: 100%;
	overflow: auto;
	box-sizing: border-box;
	border: 1px solid $theme-border-color;
	min-height: 200px;
}
.public-DraftStyleDefault-ltr {
	direction: ltr;
	text-align: left;
	margin: 8px;
}

.public-DraftEditorPlaceholder-root {
	margin: 0 8px;
}

.rdw-editor-toolbar {
	border-color: $theme-border-color;
}

.create-legal-update-scroll {
	height: calc(100vh - 114px);
    overflow-y: auto;

	.user-details-wrapper__form-wrapper {
		margin-bottom: 20px;
	}
}

.react-datepicker {
	font-family: $font-family !important;
	position: relative;
  
	.react-datepicker__navigation--next,
	.react-datepicker__navigation--previous {
	  top: 7px;
	  align-items: center;
	  justify-content: center;
	  line-height: 22px;
	  height: 22px;
	  width: 22px;
	}
  
	.react-datepicker__header {
	  padding-top: 0;
  
	  .react-datepicker__current-month {
		min-height: 22px;
		max-height: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 5px !important;
	  }
	}
  
	.react-datepicker__header__dropdown {
	  &.react-datepicker__header__dropdown--select {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
  
		.react-datepicker__month-dropdown-container {
		  &.react-datepicker__month-dropdown-container--select {
			width: 65%;
		  }
		}
  
		.react-datepicker__year-dropdown-container {
		  &.react-datepicker__year-dropdown-container--select {
			width: 35%;
		  }
		}
		.react-datepicker__year-dropdown-container,
		.react-datepicker__month-dropdown-container {
		  &.react-datepicker__year-dropdown-container--select,
		  &.react-datepicker__month-dropdown-container--select {
			position: relative;
  
			&::after {
			  content: " ";
			  border-color: $theme-border-color transparent transparent;
			  border-style: solid;
			  border-width: 5px 5px 0;
			  display: block;
			  height: 0;
			  position: absolute;
			  right: 5px;
			  top: 50%;
			  width: 0;
			  transform: translateY(-50%);
			}
  
			&::before {
			  content: "";
			  position: absolute;
			  height: 14px;
			  width: 1px;
			  background: $theme-border-color;
			  margin-right: 0.5rem;
			  right: 13px;
			  top: 7px;
			  cursor: pointer;
			  z-index: 1;
			}
  
			select {
			  width: 100%;
			  border: 1px solid $theme-border-color;
			  padding: 4px 20px 4px 4px;
			  border-radius: $base-border-radius;
			  font-size: 13px;
			  color: $theme-font-heading-color;
			  -webkit-appearance: none;
			  -moz-appearance: none;
			  position: relative;
			}
		  }
		}
	  }
	}
  
	.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
	  background-color: rgba(39, 110, 241, 0.5);
	}
  
	.react-datepicker__day--in-range,
	.react-datepicker__day--keyboard-selected {
	  background-color: rgba(39, 110, 241, 1);
	}
  
	.react-datepicker__day:hover {
	  background-color: rgba(39, 110, 241, 0.7);
	}
  }

  .react-date-picker__wrapper {
	border-bottom: 1px solid $theme-border-color;
	border-top: transparent;
	border-left: transparent;
	border-right: transparent;
	cursor: pointer;
	padding-bottom: 4px;
  }
  
  .react-datepicker__input-container input.form-control {
	// background-color: transparent;
	background-color: white !important;
	// min-width: 188px !important;
	// max-width: 188px !important;
	// width: 100%;
  }
  
  .react-datepicker-wrapper {
	// border: 1px solid $theme-border-color;
	border-radius: $base-border-radius;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: 0.3s all ease-in-out;
	&:hover {
	  // border-color: #1a73e8;
	  border-color: $theme-border-color;
	}
	min-width: 195px;
  }
  
  .react-date-picker__inputGroup__input {
	border: 0 !important;
	// padding: 0 12px;
	// padding: 0 10px;
	border-radius: $base-border-radius;
	border: $theme-border-color;
	height: 34px;
	cursor: pointer;
	min-width: 165px;
	max-width: 195px;
	width: 100%;
	// text-align: center;
	text-align: left;
	// padding: 0.375rem 0.75rem;
	padding: 0.375rem 0 0.375rem 0.75rem;
	color: $theme-font-heading-color;
	&--location {
	  height: 40px;
	}
	&:disabled {
	  // background-color: #e9ecef;
	  background-color: #f7f7f7;
	  z-index: -2;
	}
  }
  .react-date-picker__inputGroup__input_modal {
	// padding: 0 12px;
	padding: 0 10px;
	border-radius: $base-border-radius;
	border: $theme-border-color;
	height: 34px;
	cursor: pointer;
	min-width: 165px;
	// max-width: 312px;
	width: 100%;
	&--location {
	  height: 40px;
	}
	&:disabled {
	  background-color: #f7f7f7;
	  z-index: -2;
	}
  }
  .react-date-picker__inputGroup {
	display: flex;
	align-items: center;
  }
  
  .react-datepicker__month {
	margin: 0.4rem;
	text-align: center;
	background: #fff;
	// border: 1px solid #3498db;
  }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
	border-top-right-radius: 0.3rem;
	margin: 0.4rem;
	background: #fff;
  }
  
  .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
  margin-bottom: 10px !important;
}


// React Datepicker with custom apply button

.calendar-with-custom-btn-wrapper {
	padding: 5px;
	border-top: 1px solid $theme-border-color;
	text-align: right;
  
	.dashboard-wrapper__apply-btn {
	  height: 28px;
	  line-height: 28px;
	  margin-left: 5px;
	  background-color: $theme-blue-color;
	  color: $white-color;
	  border-color: $theme-blue-color;
	
	  border: 1px solid #dadce0;
	  border-radius: 4px;
	  padding: 0 16px;
	  transition: all .3s ease-in-out;
	  &:hover {
		background-color: $white-color;
		color: $theme-blue-color;
	  }
	}
  
	.dashboard-wrapper__cancel-btn {
	  height: 28px;
	  line-height: 28px;
	  margin-left: 0;
	  border: 1px solid $theme-border-color;
	  background-color: $white-color;
	  color: $theme-font-heading-color;
	  padding: 0px 16px;
	  border-radius: $base-border-radius;
	  transition: 0.3s all ease-in-out;
  
	  &:hover {
		color: $theme-blue-color;
	  }
	}
  }

  .react-datepicker__children-container {
	width: 100%;
	margin: 0;
  }
.App {
  font-family: sans-serif;
}

.dropdownselect {
  position: relative;
  color: #333;
  cursor: default;
  width:300px
}

.dropdownselect .arrow {
  border-color: #dadce0  transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: -2px;
  top: 14px;
  width: 0;
}
.dropdownselect .arrowtext{
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 12px;
  top: -5px;
  width: 0;
  font-size: 24px;
  color: #b8b9bd;
}

.dropdownselect {
.line {
  position: absolute;
  height: 20px;
  width: 1.5px;
  background: #dadce0;
  margin-right: 0.5rem;
  right: 12px;
  top: 11px;
  cursor: pointer;
}
.close{
  position: absolute;
  // height: 20px;
  // width: 1.5px;
  // background: #dadce0;
  margin-right: 0.5rem;
  right: 27px;
  top: 11px;
  cursor: pointer;
  color:'red'
}
}

.dropdownselect .arrow.open {
  border-color: transparent transparent #dadce0;
  border-width: 0 5px 5px;
}

.dropdownselect .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  // outline: none;
  padding: 8px 69px 8px 10px;
  transition: all 200ms ease;
  // width: 100%;
  // margin-left: 5px;
  width: 317px;
  // width: 296px;
&::placeholder{
  color:#dadce0;
}
}




.dropdownselect .options {
  display: none;
  background-color: #fff;
  border: 1px solid #dadce0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  // width: 100%;
  z-index: 1000;
  // width: 296px;
  width: 317px;

  -webkit-overflow-scrolling: touch;
  // margin-left: 5px;

}

.dropdownselect .options.open {
  display: block;
}

.dropdownselect .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdownselect .option.selected,
.dropdownselect .option:hover {
  background-color: #deebff;
  color: #333;
}


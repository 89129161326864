@import '@scss/common/variables';

.add-filter-wrapper {
	padding: 8px 24px;
	height: 60px;
	border-bottom: 1px solid $theme-border-color;
	&--border {
		border-top: 1px solid #f7f7f7;
	}
	.add-filter-wrapper__add-icon {
		background-color: #fd9933;
		height: 36px;
		width: 36px;
		border-radius: 50%;
		svg {
			margin: auto;
			text-align: center;
		}
	}
	.add-filter-wrapper__filter-status {
		font-weight: 300;
		color: #276ef1;
		font-size: 14px;
	}
	.add-filter-wrapper__add-filter-title {
		font-weight: 400;
		color: #276ef1;
		margin-left: 8px;
		font-size: 14px;
	}
	.add-filter-wrapper__add-filter-title span {
		font-weight: 400;
		color: #5f6368;
		margin-left: 16px;
	}
	.add-filter-wrapper__filter {
		.add-filter-wrapper__filter-form-group {
			position: relative;
			.add-filter-wrapper__filter-input-image {
				position: absolute;
				top: 50%;
				left: 8px;
				transform: translateY(-50%);
				height: 18px;
				width: 18px;
			}
			.add-filter-wrapper__filter-image-close {
				position: absolute;
				top: 50%;
				right: 8px;
				transform: translateY(-50%);
				height: 18px;
				width: 18px;
				cursor: pointer;
			}
		}
		.add-filter-wrapper__filter-form-group
			input.add-filter-wrapper__filter-search-input {
			border: 1px solid $theme-border-color;
			padding: 0 16px 0 28px;
			border-radius: 4px;
			height: 40px;
			line-height: 40px;
			min-width: 250px;
			width: 100%;
			font-size: 14px;
		}
	}
}

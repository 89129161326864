@import "@scss/common/variables";

.not-found-wrapper {
  width: 100%;
  height: calc(100vh - 165px);
  background-color: $white-color;
  cursor: pointer;
}
.not-found-wrapper__details {
  margin: auto;
  p.no-data-found {
    margin: 20px 0;
    color: $theme-font-primary-color;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
}
.not-found-image {
  height: 300px;
  width: 300px;
  text-align: center;
  border: 1px dashed $theme-border-color;
  border-radius: 4px;
  transition: 0.3ms all ease-in-out;
  &:hover {
    border-color: rgba(var(--primary-color), 1);
  }
}

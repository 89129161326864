@import 'src/assets/scss/common/_variables.scss';

.attach-files-wrapper.modal-content {
	padding: 49px 65px;
}
.attach-files-wrapper.modal-body {
	padding: 16px 0;
}
.attach-files-wrapper__title-section {
	border-bottom: 1px solid $theme-border-color;
}
.attach-files-wrapper__title {
	color: #202124;
	font-size: 34px;
	font-weight: 500;
}
.custom-inputfile {
	border: 1px dashed $theme-border-color;
	width: 100%;
	min-width: 300px;
	padding: 24px 0;
	border-radius: 4px;
	justify-content: center;
}
.input-file-label:hover .custom-inputfile {
	border: 1px dashed #276ef1;
}
.custom-inputfile__text {
	color: #5f6368;
	margin-bottom: 4px;
	text-align: center;
}
.attach-files-wrapper__select {
	color: #02052b;
	font-size: 22px;
	margin-bottom: 16px;
	font-weight: $normal-font-weight;
}
.attach-files-wrapper__files {
	background-color: #f7f9fe;
	padding: 10px 16px;
	margin-bottom: 10px;
}

@import "@pages/Dashboard/datatable.scss";
@import "@scss/common/variables";
.client-list-wrapper {
  width: 100%;
  .client-list-wrapper__add-search {
    background-color: #ff9933;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    svg {
      margin: auto;
    }
  }
  .client-list-wrapper__search {
    padding: 0px 17px;
    border-bottom: 1px solid $theme-border-color;
    .client-list-wrapper__search-title {
      font-size: 10px;
      color: $theme-font-primary-color;
      margin-top: 5px;
    }
  }
}


//Legal update card wrap
.legal-update-main-wrap {
    // border: 1px solid $theme-border-color;
    padding: 16px;
    // background-color: $white-color;
    // border-radius: $base-border-radius;
    
    .legal-update-card-holder {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(4, 1fr);

        .legal-update-card {
            border: 1px solid $theme-border-color;
            border-radius: $base-border-radius;
            // max-width: 318px;
            width: 100%;
            padding: 16px 16px 32px;
            min-width: 33.33%;
            transition: 0.3s all ease-in-out;
            position: relative;

            &:hover {
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.161);
            }

            .legal-tags-wrap {
                margin-bottom: 6px;
                .legal-tags {
                    color: #115baa;
                    background: #D0DDEE;
                    font-size: 12px;
                    padding: 2px 4px;
                    margin-right: 5px;
                    border-radius: 2px;

                    &.states-name-tags {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #ffffff;
                        background: #A289DD;
                    
                    }
                }
            }

            .legal-title {
                font-size: 14px;
                font-weight: $medium-font-weight;
            }

            .legal-timestamp {
                font-size: 10px;
                color: rgba(0,0,0,.5);
                margin-bottom: 4px;

            }

            .legal-desc {
                font-size: 13px;
                margin-bottom: 6px;
                word-wrap: break-word;
            }

            .read-more-link {
                position: absolute;
                bottom: 16px;
                right: 16px;
                color: #1a73e8;
                cursor: pointer;

                
                a {
                    margin: 0 5px;
                    position: relative;

                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    &::after {
                        content: "";
                        height: 18px;
                        width: 1px;
                        background-color: $theme-border-color;
                        display: inline-block;
                        position: absolute;
                        right: -5px;
                    }
                }
            }
        }
    }
}

@import "@scss/common/variables";
p.show-rows-count {
  color: #5f6368;
  font-size: 14px;
  margin-right: 16px;
}
select.show-select {
  border-top: 1px solid $white-color;
  border-left: 1px solid $white-color;
  border-right: 1px solid $white-color;
  border-bottom: 1px solid $theme-border-color;
  color: $theme-font-primary-color;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
}
.show-count {
  color: #5f6368;
  font-size: 14px;
}
// .pagination li a {
//   font-size: 16px !important;
//   font-weight: 500;
//   color: #5f6368;
//   &:not(:last-child) {
//     margin-right: 16px;
//   }
// }
// .pagination li:nth-child(3),
// .pagination li:nth-child(4) {
//   display: none;
// }
// .pagination li.active a {
//   background-color: #276ef1 !important;
//   color: #ffffff;
// }
// .pagination li:nth-child(3) a,
// .pagination li:nth-child(4) a {
//   background-color: $theme-border-color;
//   color: #276ef1;
// }

// .pagination li:nth-child(3):active a,
// .pagination li:nth-child(4):active a {
//   background-color: #276ef1;
//   color: #ffffff;
// }
// .pagination li:nth-child(3):hover a,
// .pagination li:nth-child(4):hover a {
//   background-color: #276ef1;
//   color: #ffffff;
// }
// .pagination li:nth-child(1):hover a,
// .pagination li:nth-child(2):hover a,
// .pagination li:last-child:hover a,
// .pagination li:nth-last-child(2):hover a {
//   background-color: transparent;
// }

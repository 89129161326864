@import '@scss/common/variables';

.header__dropdown .dropdown-content {
	height: 32px;
	width: 32px;
	border-radius: 50%;
	background-color: #1d4d40;
	color: $white-color;
}

.header-wrapper {
	padding: 6px 0;
	border-bottom: 1px solid $theme-border-color;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: $white-color;
	z-index: 7;
}

.bulk-dropdown .dropdown .btn {
	height: 34px;
}

.bulk-dropdown .btn {
	padding: 0 0px;
	border: none;
}

.bulk-dropdown .dropdown {
	&:before {
		height: 0;
		width: 0;
		top: 0;
	}
}

.bulk-dropdown .dropdown-menu {
	padding: 0;
	transform: translate3d(0, 47.5px, 0) !important;
	min-width: 240px;
}

.bulk-dropdown .dropdown-item {
	padding: 0px 0 8px !important;
	margin: 0;
	text-align: center;

	p {
		text-align: center;
	}

	&:hover {
		color: #1a73e8;
		background-color: transparent !important;
	}

	&.active {
		color: #1a73e8;
		background-color: transparent !important;
	}
}

.dropdown-content {
	margin: 0 auto;
}

.header-btn {
	height: 34px;
	line-height: 34px;
	border: 1px solid $theme-border-color;
	padding: 0 24px;
	border-radius: 20px;
	margin-top: 8px;
	background-color: $white-color;
	font-weight: 400;
}
.header-apply-btn {
	height: 34px;
	line-height: 34px;
	border: 1px solid $theme-border-color;
	padding: 0 24px;
	background-color: $white-color;
	font-weight: 400;
	background-color: $white-color;
	border-radius: $base-border-radius;
	line-height: 1;
	transition: 0.3s all ease-in-out;
	color: #3c3c3c;
	&:hover {
		border-color: #1a73e8;
		color: #1a73e8;
	}
}

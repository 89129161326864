@import '@scss/common/variables';

.user-details-wrapper {
	.user-details-wrapper__add-user-title {
		margin-left: 8px;
	}
}
.user-details-wrapper__inner-header {
	position: fixed;
	top: 65px;
	left: 0;
	right: 0;
	z-index: 7;
	background-color: white;
}
.rolls-wrapper {
	max-width: 752px;
	width: 100%;
	margin: 135px auto 0;
	.rolls-wrapper__form-group input.rolls-form-control {
		max-width: 364px;
		width: 100%;
	}
	.rolls-wrapper__form-group label.rolls-label {
		font-size: 14px;
		color: $theme-font-primary-color;
		font-weight: 500;
	}
	.rolls-wrapper__permission {
		margin-bottom: 24px;
		.rolls-wrapper__permission-title {
			font-size: 20px;
			color: $theme-font-heading-color;
			margin-bottom: 5px;
			font-weight: 400;
		}
		.rolls-wrapper__permission-text {
			font-size: 12px;
			color: $theme-font-primary-color;
		}
	}
}
.rolls-details-wrapper__title {
	text-transform: capitalize;
	font-size: 14px;
	color: $theme-font-heading-color;
	font-weight: 500;
	padding-right: 8px;
	background-color: $white-color;
	z-index: 2;
}
.rolls-details-wrapper__form-group {
	background-color: $white-color;
	z-index: 2;
	input.rolls-details-form-control {
		margin: 0 8px;
	}
}
.rolls-details-list__form-group label.rolls-detail__select-all,
.rolls-details-list__form-group label.rolls-detail__select {
	color: $theme-font-heading-color;
	font-size: 14px;
	font-weight: 400;
}
.rolls-details-wrapper {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: 10px;
		width: 100%;
		height: 2px;
		background-color: #dadada;
	}
}

@import "_variables.scss";
@import "_extends.scss";

@font-face {
  font-family: "Ascent-font";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/ProductSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: italic;
  font-weight: normal;
  src: url("../../fonts/ProductSans-Italic.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: normal;
  font-weight: 200;
  src: url("../../fonts/ProductSans-Thin.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/ProductSans-Light.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/ProductSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/ProductSans-Black.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: italic;
  font-weight: 200;
  src: url("../../fonts/ProductSans-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: italic;
  font-weight: 300;
  src: url("../../fonts/ProductSans-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: italic;
  font-weight: 500;
  src: url("../../fonts/ProductSans-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/ProductSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: italic;
  font-weight: 700;
  src: url("../../fonts/ProductSans-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Ascent-font";
  font-style: italic;
  font-weight: 600;
  src: url("../../fonts/ProductSans-BlackItalic.woff") format("woff");
}

* {
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
}

html {
  font-size: $base-font-size + px;
  color: $theme-primary-color;

  @media (max-width: $mobile-devices-max-width+px) {
    font-size: $base-font-size-mobile + px;
  }

  @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    font-size: $base-font-size-mobile + px;
  }
}

body {
  font-family: $font-family;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: $normal-font-weight;
  color: $theme-font-heading-color;
  background-color: $body-background-color;
}

a {
  text-decoration: none !important;
  //color: $theme-primary-color;
}

p {
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a:focus,
a:hover,
a:active {
  outline: medium none !important;
  text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

h1 {
  @extend %medium-bold-font;
}

h2 {
  @extend %medium-bold-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  // color: $theme-font-heading-color;
}

h3,
h4,
h5,
h6 {
  @extend %bold-font;
}

h1 {
  font-size: ($heading1-font-size/$base-font-size) + rem;

  @media (max-width: $mobile-devices-max-width+px) {
    font-size: ($heading1-mobile-font-size/$base-font-size-mobile) + rem;
  }

  @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    font-size: ($heading1-tablet-font-size/$base-font-size-mobile) + rem;
  }
}

h2 {
  font-size: ($heading2-font-size/$base-font-size) + rem;

  @media (max-width: $mobile-devices-max-width+px) {
    font-size: ($heading2-mobile-font-size/$base-font-size-mobile) + rem;
  }

  @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    font-size: ($heading2-tablet-font-size/$base-font-size-mobile) + rem;
  }
}

h3 {
  font-size: ($heading3-font-size/$base-font-size) + rem;

  @media (max-width: $mobile-devices-max-width+px) {
    font-size: ($heading3-mobile-font-size/$base-font-size-mobile) + rem;
  }

  @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    font-size: ($heading3-tablet-font-size/$base-font-size-mobile) + rem;
  }
}

h4 {
  font-size: ($heading4-font-size/$base-font-size) + rem;

  @media (max-width: $mobile-devices-max-width+px) {
    font-size: ($heading4-mobile-font-size/$base-font-size-mobile) + rem;
  }

  @media (min-width: $tablet-devices-min+px) and (max-width: $tablet-devices-max+px) {
    font-size: ($heading4-tablet-font-size/$base-font-size-mobile) + rem;
  }
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button,
a {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@import "@scss/common/variables";

.setup-wrapper {
  max-width: 650px;
  width: 100%;
  margin: 24px auto;
  border: 1px solid $theme-border-color;
  padding: 24px;
  border-radius: 4px;
}
.setup-wrapper__details {
  align-items: flex-start;
  gap: 4px;
  &:not(:last-child) {
    margin-bottom: 11px;
  }
  .setup-wrapper__details-title {
    font-size: 14px;
    color: $theme-font-heading-color;
    font-weight: 500;
  }
  .setup-wrapper__details-text {
    font-size: 12px;
    color: $theme-font-primary-color;
  }
}
.setup-wrapper__header {
  margin-bottom: 19px;
  .setup-wrapper__header-title {
    color: $theme-font-heading-color;
    font-size: 20px;
    font-weight: 500;
  }
  .setup-wrapper__header-text {
    font-size: 12px;
    color: $theme-font-primary-color;
  }
}

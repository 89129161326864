@import '@scss/common/variables';

.add-filter-wrapper__filter-container {
	max-width: 150px;
	width: 100%;
	&:last-child {
		margin-left: 8px;
	}

	.add-filter-wrapper__upload-image {
		height: 24px;
		width: 24px;
		margin: 0 auto;
		cursor: pointer;
	}
	.add-filter-wrapper__filter-title {
		font-size: 12px;
		font-weight: 400;
		text-align: center;
		min-width: 77px;
		width: 100%;
		color: $theme-font-primary-color;
	}
}

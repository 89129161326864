@import "@scss/common/variables";

.filter-form-wrappper {
  border-bottom: #f7f7f7;
  // top: -63px;
  // position: relative;
  //background-color: #fff;
  display: none;
  &.active {
    display: block;
  }
  .filter-form-wrappper__cta-wrapper {
    padding: 10.7px 24px;
    .filter-form-wrappper__cta-btn {
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      border: none;
      border-radius: 2px;
      background-color: $white-color;
      text-transform: uppercase;
      &--search {
        color: #ffff;
        background-color: #276ef1;
        margin-right: 8px;
      }
      &--disable {
        color: #a8a8a8;
        background-color: #e0e0e0;
        margin-right: 8px;
      }
      &--reset {
        color: #276ef1;
      }
    }
  }
  .filter-form-wrappper__container {
    margin: 0 24px;
    border-bottom: 1px solid $theme-border-color;

    .filter-form-wrappper__title {
      color: #3c4043;
      font-size: 16px;
      font-weight: 500;
    }
    .filter-form-wrappper__location {
      margin-bottom: 16px;
      .filter-form-wrappper__location-input {
        position: relative;
        max-width: 307px;
        width: 100%;
        &--law {
          max-width: 638px;
          width: 100%;
        }
        .filter-form-wrappper__image {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
        }
      }
      .filter-form-wrappper__location-form-group label.filter-form-wrappper__label {
        color: #5f6368;
        font-size: 12px;
        margin-bottom: 4px;
        font-weight: 500;
      }
      .filter-form-wrappper__location-form-group input.filter-form-wrappper__form-control {
        max-width: 307px;
        width: 100%;
        min-width: 307px;
        &:focus {
          outline: none;
          border-color: $theme-border-color;
          box-shadow: none;
        }
        &--law {
          max-width: 638px;
          width: 100%;
          min-width: 638px;
        }
      }
      &:not(:last-child) {
        border-right: 1px solid $theme-border-color;
      }
    }
  }
  .gap {
    gap: 75.5px;
  }
}


.filter-form-legal-update {
  &.filter-form-wrappper {
    .gap {
      gap: 35px;
    }

    .filter-form-wrappper__location-form-group {
      .select-dd-new {
        .react-datepicker-wrapper {
          border: 1px solid $theme-border-color;
        }
      }
    }
  }
}
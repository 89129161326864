@import 'src/assets/scss/common/_variables.scss';

.comments-popup.modal-content {
	padding: 49px 65px;
	max-height: 600px;
	height: 600px;
	overflow-y: auto;
}
.comments-popup .modal-body {
	padding: 16px 0;
}

.comments-popup-wrapper__comments-title {
	font-weight: $normal-font-weight;
	margin-right: 8px;
	text-transform: capitalize;
	font-size: 16px;
}
.comments-popup-wrapper__comments-list-item {
	position: relative;
	padding-right: 25px;
	font-size: 12px;
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -12px;
		height: 16px;
		width: 1px;
		background-color: $theme-border-color;
	}
}
.comments-popup__header {
	border-bottom: 1px solid $theme-border-color;
}
.comments-popup__vendor-name {
	color: #02052b;
	font-weight: $medium-font-weight;
	margin-bottom: 4px;
	font-size: 12px;
}
.comments-popup__vendor-details {
	border-bottom: 1px solid $theme-border-color;
}
.comments-popup__vendor-text {
	color: #5f6368;
	font-weight: $normal-font-weight;
	font-size: 12px;
	margin-bottom: 16px;
}
.comments-popup__form-control {
	min-width: 612px;
	margin-left: 16px;
	&:focus {
		box-shadow: none;
	}
}

.vendorInfo-wrapper {
	height: 36px;
	width: 36px;
	background-color: green;
	border-radius: 50%;
}
.vendorInfo-wrapper__text {
	margin: auto;
	color: $white-color;
}
.gap {
	gap: 16px;
}
.vendorInfo-wrapper__comments-title {
	font-weight: $medium-font-weight;
}

.comments-modal {
	padding: 49px 65px;
	max-height: 600px;
	height: 600px;
	overflow-y: auto;
}

@import "@scss/common/variables";
.uploaded-wrapper {
  padding: 0 24px;
}
table.uploaded-wrapper__table {
  // max-width: calc(100% - 48px);
  margin: 24px auto;
  border-collapse: collapse;

  th,
  td {
    border-bottom: 1px solid $theme-border-color;
    padding-left: 16px;
  }
  th {
    padding-bottom: 17px;
    .uploaded-wrapper__table-header-details {
      .uploaded-wrapper__table-header-title {
        color: $black-color;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
      }
      .uploaded-wrapper__table-header-text {
        color: #5f6368;
        font-size: 12px;
        font-weight: 400;
        height: 14.4px;
      }
    }
  }
  tbody {
    tr {
      transition: 0.3ms all ease-in-out;
      &:hover {
        background-color: #f7faff;
      }
      td {
        padding: 18px 0 16px 16px;
        cursor: pointer;
        .uploaded-wrapper__table-body-details {
          .uploaded-wrapper__table-title {
            color: #5f6368;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 3px;
            &--green-color {
              color: #05a357;
            }
            &--blue-color {
              color: #1a73e8;
            }
            &--black-color {
              color: $black-color;
            }
          }
          p {
            color: #5f6368;
            font-size: 12px;
            height: 14.4px;
          }
        }
      }
    }
  }
}

@import "@scss/common/variables";

.effects{
    background-color: rgba(var(--primary-color), 0.06);
    color: rgba(var(--primary-color), 1);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background-color: rgba(var(--primary-color), 1);
    }
}

.navigation-menu .material-symbols-outlined {
  font-size: 18px;
}

.navigation-menu__menu-icon {
  margin-right: 10px;
}

.navigation-menu a.nav-link {
  color: #3c4043;
  padding: 0;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
}

.dropdown-arrow {
  width: 18px;
  height: 18px;
}
.navigation-menu {
  min-width: 168px;
  max-width: 168px;
}

.navigation-menu__menu-item {
  padding: 10px 0 10px 18px;
  position: relative;
  z-index: 1;
  transition: 0.3s all ease-in-out;
}
.navigation-menu__menu-elements {
  max-width: 115px;
  width: 100%;
}

.navigation-menu__sub-menu li {
  padding: 10px 0 8px 24px;
}
.navigation-menu__no-sub-menu-item {
  position: relative;
  &.active{
    @extend .effects;
  }
}
.navigation-menu__sub-menu-link {
  transition: 0.3s all ease-in-out;
  &.active{
    @extend .effects;
  }
  &:hover {
  @extend .effects;
  }
}
.navigation-menu__sub-menu-link {
  color: rgba(var(--secondary-color), 1);
  font-weight: 500;
  font-size: 14px;  
}
.left-navigation__checkbox:checked ~ .navigation-menu__sub-menu {
  max-height: 100vh;
  overflow: initial;
  margin-top: 8px;
}
.navigation-menu__sub-menu {
  position: relative;
  margin: 0 7px;
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease-in-out;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    left: 0px;
    top: 2px;
    width: 3px;
    background-color: rgba(var(--secondary-color), 1);
    opacity: 0.3;
  }
}

@import '@scss/common/variables';
.main-wrapper {
	margin-top: 65px;
	width: 100%;
	display: flex;
	height: calc(100vh - 65px);
	.main-user-wrapper {
		max-height: calc(100vh - 65px);
		height: calc(100vh - 65px);
		overflow-y: auto;
		border-right: 1px solid $theme-border-color;
	}
	.dashboard-wrapper {
		max-width: calc(100% - 170px);
		width: 100%;
		overflow-x: auto;
		.dashboard-wrapper__header {
			height: 50px;
			padding: 15px 24px;
			box-shadow: 0px 3px 6px #00000029;
			// position: fixed;
			// top: 65px;
			// left: 170px;
			// right: 0;
			background-color: $white-color;
			z-index: 3;
			position: sticky;
			top: 0;
			.dashboard-wrapper__header-title {
				color: #3c4043;
				font-size: 20px;
				font-weight: 400;
			}
		}
	}
}
.dashboard-wrapper__input-field {
	margin-left: 8px;
	max-width: 300px;
	width: 100%;
	min-width: 300px;
}

@import '@scss/common/variables';
.legislation-wrapper {
	max-width: 753px;
	max-height: 536px;
	height: 536px;
	overflow-y: auto;
	.legislation-wrapper__legislation-header {
		position: relative;
		img.legislation-wrapper-image {
			width: 185px;
			height: 80px;
			position: absolute;
			top: 10px;
			right: 0;
		}
		.legislation-wrapper__legislation-header-title {
			width: 100%;
			font-size: 20px;
			color: $theme-font-heading-color;
			font-weight: 500;
			padding: 32px 0 24px;
		}
	}
}
.legislation-wrapper__popup {
	padding: 0 47px 34px;
	.legislation-wrapper__form-group label.legislation-wrapper__form-label {
		font-size: 15px;
		margin-bottom: 5px;
		color: #02052b;
		font-weight: 500;
	}
}
.modal-dialog.legislation-popup {
	max-width: 753px;
}

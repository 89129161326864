.navigation-bar-wrapper {
  background-color: #1a73e8;
  // padding: 11px 24px;
  color: #ffffff;
  padding:8px 24px;
  height: 40px;
  width: 100%;
  // z-index: 5;
}
p.navigation-bar-wrapper__selected {
  font-size: 14px;
  font-weight: 300;
}
.navigation-bar-wrapper__inactive-count {
  margin-left: 16px;
}
.navigation-bar-wrapper__inactive-count h6 {
  color: #ffffff;
  margin-left: 8px;
  font-weight: 400;
}
.navigation-bar-wrapper__close-btn {
  cursor: pointer;
}

@import "@scss/common/variables";
.overview-wrapper {
  max-width: calc(100% - 168px);
  width: 100%;
  margin: 16px auto;

  .overview-wrapper__header {
    margin: 24px 0 16px;
    .overview-wrapper__header-title {
      color: $theme-font-heading-color;
      font-size: 20px;
      font-weight: 500;
      span {
        color: #05a357;
        font-size: 20px;
      }
    }
    .overview-wrapper__header-date {
      color: $theme-font-heading-color;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .box-gap {
    gap: 16px;
    flex-wrap: wrap;
  }
}

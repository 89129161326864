@import "@scss/common/variables";

.breakup-box {
  max-width: 234px;
  width: 100%;
  padding: 16px 13px 16px 16px;
  border: 1px solid rgba(39, 110, 241, 0.33);
  border-radius: 4px;
  position: relative;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .breakup-box__title {
    color: #3c4043;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 4px;
  }
  .breakup-box__text {
    color: $theme-font-primary-color;
    font-size: 14px;
  }
  .breakup-box-image {
    position: absolute;
    right: 13px;
    top: 16px;
  }
}

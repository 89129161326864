@import '@scss/common/variables';
.user-details-wrapper {
	margin-top: 65px;
	.user-details-wrapper__header {
		border-bottom: 1px solid $theme-border-color;
		padding: 0 24px 0;

		.user-details-wrapper__header-back-link {
			max-width: 24px;
			width: 100%;
		}
		.user-details-wrapper__add-user-info {
			text-align: center;
			color: rgba(var(--primary-color), 1);
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: -15px;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: rgba(var(--primary-color), 1);
			}
		}
	}

	.user-details-wrapper__form-wrapper {
		max-width: 744px;
		width: 100%;
		margin: 20px auto;
		z-index: 2;
		.user-details-wrapper__form-roles
			.form-group
			.user-details-wrapper__form-control {
			width: 100%;
		}
		.user-details-wrapper__form-batch-wrapper {
			width: 100%;
			border: 1px solid $theme-border-color;
			padding: 8px;
			border-radius: 4px;
			gap: 8px;
			button.badge {
				color: var(--primary-color);
				background-color: #fff5ea;
				border-radius: 18px;
				border: none;
				font-weight: 400;
				height: 34px;
				line-height: 34px;
				padding: 0 16px;
				font-size: 14px;
				img.close-btn {
					margin-left: 8px;
				}
			}
		}
	}
	.user-details-wrapper__form {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 24px;
		&--grid-gap-vendor-profile {
			gap: 0 24px;
		}
		&--org-profile {
			gap: 0 24px;
		}
	}
	.user-details-wrapper__form-group {
		min-width: 360px;
		width: 100%;
		margin-bottom: 0;
	}
}

.user-details-wrapper__form-group input.jsx-4179805763 {
	height: 44px;
	// border:1px solid $theme-border-color;
	border-radius: 4px;
}
.user-details-wrapper__form-group input.form-control {
	min-width: 364px;
	color: #333333;
}
.form-group input.user-details-wrapper__form-control {
	height: 40px;
	line-height: 40px;
}
.css-1s2u09g-control {
	min-height: 44px !important;
}
.form-control-timezone {
	height: 44px !important;
	min-height: 44px !important;
	line-height: 24px;
	border: 1px solid $theme-border-color;
	border-radius: 4px;
}
.jsx-4179805763 {
	z-index: 5;
}

.user-details-wrapper__cta-btns {
	display: inline-flex;
	align-items: center;
	gap: 8px;
	.user-details-wrapper__cta-btn {
		height: 40px;
		line-height: 40px;
		border-radius: 4px;
		border: 1px solid $theme-border-color;
		background-color: $white-color;
		transition: 0.3s all ease-in-out;
		min-width: 98px;
		width: 100%;
		cursor: pointer;
		&--primary {
			color: rgba(var(--button-primary), 1);
			background-color: #ffffff;
			margin-right: 8px;
			&:hover {
				background-color: rgba(var(--button-primary), 1);
				color: #ffffff;
			}
		}
		&--secondary {
			background-color: rgba(var(--button-primary), 1);
			color: #ffffff;

			margin-right: 8px;
			&:hover {
				color: rgba(var(--button-primary), 1);
				background-color: #ffffff;
			}
		}
	}
}

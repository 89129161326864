@import "@pages/Dashboard/datatable.scss";
@import "@scss/common/variables";
.client-list-wrapper {
  width: 100%;
  .client-list-wrapper__add-search {
    background-color: #ff9933;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    svg {
      margin: auto;
    }
  }
  .client-list-wrapper__search {
    padding: 0px 17px;
    border-bottom: 1px solid $theme-border-color;
    .client-list-wrapper__search-title {
      font-size: 10px;
      color: $theme-font-primary-color;
      margin-top: 5px;
    }
  }
}


@import '@scss/common/variables';
.uploaded-details-wrapper {
	margin: 0 49px;
}
.back-link-image {
	margin: 24px 24px 37px;
}
.custom-checkbox {
	border: 1px solid $theme-border-color;
	height: 18px;
	width: 18px;
	border-radius: 4px;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
	background-color: #fff;
	display: flex;
}
.uploaded-tick {
	margin: auto;
	display: none;
}
.uploaded-details-wrapper-form-group input.default-checkbox {
	opacity: 0;
	visibility: hidden;
}
.uploaded-details-wrapper-form-group
	input.default-checkbox:checked
	~ .custom-checkbox {
	background-color: #1a73e8;
	border-radius: 4px;
	.uploaded-tick {
		display: block;
	}
}
.uploaded-details-wrapper__header {
	border-bottom: 1px solid $theme-border-color;
	padding-bottom: 16px;
	align-items: flex-start;
}
.uploaded-details-wrapper__body {
	padding: 16px 0;

	align-items: flex-start;
}
.uploaded-details-wrapper-form-group {
	margin-right: 16px;
}
.uploaded-details-wrapper__rules-wrapper,
.uploaded-details-wrapper__location-wrapper {
	max-width: 240px;
	width: 100%;
	margin-right: 40px;
}
.uploaded-details-wrapper__frequency-wrapper {
	max-width: 240px;
	width: 100%;
	margin-right: 40px;
}
.uploaded-details-wrapper__status-wrapper {
	max-width: 140px;
	width: 100%;
}
.uploaded-details-wrapper__icons-wrapper {
	margin-left: 50px;
	display: flex;
	align-items: center;
	gap: 16px;
	justify-content: flex-end;
	max-width: 250px;
	width: 100%;
}
.uploaded-details-wrapper__header-title {
	color: #02052b;
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	margin-bottom: 3px;
}
.uploaded-details-wrapper__header-text {
	color: #5f6368;
	font-size: 12px;
	font-weight: 400;
}
.uploaded-details-wrapper__body-title {
	color: #5f6368;
	font-weight: $normal-font-weight;
	font-size: 16px;
	line-height: 1.2;
	margin-bottom: 3px;
	&--red {
		color: #d7242e;
	}
	&--green {
		color: green;
	}
}
.uploaded-details-wrapper__body-text {
	color: #5f6368;
	font-size: 12px;
	font-weight: 400;
	min-width: 240px;
	width: 100%;
}
.uploaded-details-wrapper__agreement {
	border: 1px solid #e8f0fe;
	border-radius: 4px;
	max-width: 202px;
	width: 100%;
	padding: 5px 8px;
}
.uploaded-details-wrapper__agreement-wrapper {
	gap: 40px;
	border-bottom: 1px solid $theme-border-color;
	padding-bottom: 16px;
	padding-left: 26px;
}
.uploaded-details-wrapper__agreement-text {
	font-size: 12px;
	color: #3c4043;
	font-weight: 500;
	margin: 0 4px;
	max-width: 125px;
}

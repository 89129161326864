.centered {
    height: 65vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desc{
      text-align: center;
      font-size: large;
      font-weight: 500;
  }
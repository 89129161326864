@import "@scss/common/variables";
.add-comments-wrapper {
  max-width: 1150px;
  width: 100%;
  margin: 24.5px auto 24px;
  @media (min-width: 992px) and (max-width: 1200px) {
    max-width: 900px;
    min-width: 900px;
  }
}
.form-group label.add-comments-wrapper__label {
  height: 48px;
  width: 48px;
  background-color: #689f38;
  color: $white-color;
  display: flex;
  text-align: center;
  border-radius: 50%;
  margin: 0;
  h6 {
    margin: auto;
    font-size: 20px;
    font-weight: 400;
  }
}
.add-comments-wrapper__label-container {
  .add-comments-wrapper__label {
    height: 48px;
    width: 48px;
    background-color: #689f38;
    color: $white-color;
    display: flex;
    text-align: center;
    border-radius: 50%;
    h6 {
      margin: auto;
      font-size: 20px;
      font-weight: 400;
    }
  }
}
.add-comments-wrapper__textarea {
  margin-left: 16px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $theme-border-color;
}
.add-comments-wrapper__cta-wrapper {
  justify-content: end;
}
.add-comments-wrapper__cta-btn {
  background-color: $theme-border-color;
  border: none;
  color: $white-color;
  height: 40px;
  line-height: 40px;
  padding: 0 27px;
  border-radius: 4px;
}

.add-comments-wrapper__header-list {
  gap: 24px;
}
.add-comments-wrapper__header-list-items {
  position: relative;
  &:not(:first-child) {
    color: #1a73e8;
    font-weight: 300;
    font-size: 12px;
  }

  &:not(:last-child) {
    &:before {
      content: "";
      position: absolute;
      height: 15px;
      width: 1px;
      background-color: $theme-border-color;
      top: 50%;
      transform: translateY(-50%);
      right: -16px;
    }
  }
  &:first-child {
    font-weight: 500;
    line-height: 1;
    font-size: 16px;
  }
}
.add-comments-wrapper__header-description {
  margin: 4px 0;
  gap: 24px;
}
.add-comments-wrapper__header-description-list {
  position: relative;
  font-size: 12px;
  font-weight: 300;
  &:not(:last-child) {
    &:before {
      content: "";
      position: absolute;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      background-color: $theme-font-primary-color;
      top: 50%;
      transform: translateY(-50%);
      right: -16px;
    }
  }
}
.add-comments-wrapper__header-description-text {
  font-size: 16px;
  color: $theme-font-primary-color;
  font-weight: 400;
  margin-left: 63px;
}

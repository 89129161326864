@import '@scss/common/variables';

.checkpoint-active-details-wrapper__form-content {
	max-width: 744px;
	margin: 0 auto;
	width: 100%;
}

.checkpoint-active-wrapper {
	position: relative;
	top: 65px;
	width: 100%;
}

.nav-link-image {
	margin: 24px 24px;
}

.user-details-wrapper__header--new-header {
	position: sticky;
	top: 65px;
	background-color: #fff;
	z-index: 1;
}

.checkpoint-active-details-wrapper__form-wrapper {
	padding-top: 66px;
}

.css-1s2u09g-control {
	border-color: $theme-border-color !important;
	min-height: 40px;
}

.css-14el2xx-placeholder {
	color: #dadce0 !important;
}

.user-details-wrapper__form-group label.checkpoint-active-details__label {
	font-weight: 500;
	color: #5f6368 !important;
	font-size: 12px;
}
.user-details-wrapper__form-group .form-group {
	.css-1pahdxg-control {
		box-shadow: none;
		border-color: $theme-border-color !important;
		min-height: 44px;
	}
	.css-1pahdxg-control:hover {
		border-color: $theme-border-color !important;
	}
	textarea {
		color: #333333;
		&:focus {
			box-shadow: none;
			border-color: $theme-border-color;
		}
	}
}

.user-details-wrapper__inner-header {
	position: fixed;
	top: 65px;
	// left: 168px;
	right: 0;
	z-index: 3;
	background-color: white;
	border-left: 1px solid $theme-border-color;
}

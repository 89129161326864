@import "@scss/common/variables";

.list-management-wrapper {
  margin-top: 65px;
  max-width: 100%;
  overflow-x: scroll;
}
.list-managemnet-wrapper__country-table {
  // margin-top: 50px;
  padding-top: 32px;
  border-right: 1px solid $theme-border-color;
  min-width: 502px;
  width: 100%;
  .list-managemnet-wrapper__country-common-container {
    padding-left: 24px;
    border-bottom: 1px solid $theme-border-color;

    .list-managemnet-wrapper__form-group {
      position: relative;
      max-width: 308px;
      width: 100%;
      input.list-managemnet-wrapper__form-control {
        max-width: 308px;
        width: 100%;
        border: 1px solid $theme-border-color;
        border-radius: 4px;
        font-size: 16px;
        height: 44px;
        line-height: 44px;
        &::placeholder {
          color: #3c4043;
        }
        cursor: pointer;
      }
      .list-managemnet-wrapper__form-add-icon {
        position: absolute;
        right: 12px;
        top: 38%;
        transform: translateY(-50%);
      }
    }
  }
}
